@import '../../theme/utils/variables.scss';
@import '../../theme/utils/mixins.scss';

.survey-transitionSlide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin: -2rem;
  padding: 2rem;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.3);
  }
  &.error {
    height: 100vh;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    .transitionSlide-button-indication-key {
      display: none;
    }
  }
  &.first-slide {
    .transitionSlide-content {
      .transitionSlide-textView {
        align-items: center;
        .transitionSlide-title .transitionSlide-title-text {
          font-weight: 400;
          display: inline-block;
          max-width: 29rem;
        }
      }
    }
  }
  .transitionSlide-logo {
    width: 12.5rem;
    height: 6.125rem;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .transitionSlide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    .transitionSlide-textView {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      max-width: 34rem;
      &.extremesSlide {
        align-items: center;
      }
      .transitionSlide-title {
        text-align: center;
        .transitionSlide-title-text {
          font-family: $textFont;
          font-size: $extralarge-font-size;
          text-align: center;
          color: $titleColor;
        }
      }
      .transitionSlide-subtitle {
        margin-top: 1rem;
        display: flex;
        line-height: 1.5rem;
        .transitionSlide-subtitle-text {
          font-family: $textFont;
          font-size: $medium-font-size;
          font-weight: normal;
          color: $subtitleColor;
          text-align: center;
        }
      }
      .transitionSlide-button {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .transitionSlide-button-indication {
          margin-left: 1rem;
          font-family: $textFont;
          font-size: $medium-font-size;
        }
        .transitionSlide-button-indication-key {
          display: flex;
        }
      }
    }
  }
  &.pageNotFound {
    height: 100vh;
    display: flex;
    padding: 0rem;
    margin: 0rem;
    .transitionSlide-content {
      .transitionSlide-textView {
        .transitionSlide-button {
          .transitionSlide-button-indication-key {
            display: none;
          }
        }
        .transitionSlide-title {
          .transitionSlide-title-text {
            color: black;
          }
        }
        .transitionSlide-subtitle {
          .transitionSlide-subtitle-text {
            color: $titleColor;
          }
        }
      }
    }
  }
}

//button color
.MuiButtonBase-root.MuiButton-root.confirmation-button-slide {
  background-color: $buttonColor;
  color: $buttonTextColor;
  border-radius: 0.125rem;
  &:hover {
    @include lightenBackgroundColor();
  }
}

@media only screen and (max-width: $tablet-width) and (orientation: portrait) {
  .transitionSlide-title {
    max-width: $tablet-width;
    word-break: break-word;
  }
}

@media only screen and (max-width: $desktop-width) {
  .survey-transitionSlide {
    justify-content: flex-end;
    padding: 0 1rem;
    .transitionSlide-button {
      flex-grow: 0.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .transitionSlide-content {
      padding: 0 1rem;
      .transitionSlide-textView {
        align-items: center;
        .transitionSlide-subtitle {
          text-align: center;
        }
        .transitionSlide-button {
          width: 100%;
          .MuiButtonBase-root.MuiButton-root.confirmation-button-slide.MuiButton-contained {
            min-height: 2.75rem;
          }
          .transitionSlide-button-indication,
          svg {
            display: none;
          }
        }
      }
    }
    &.first-slide {
      .transitionSlide-content {
        .transitionSlide-textView {
          align-items: center;
          .transitionSlide-title .transitionSlide-title-text {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
