@import '../../theme/utils/variables.scss';
@import '../../theme/utils/mixins.scss';

.survey-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  .question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    &.centered {
      align-items: center;
    }
    &.horizontal.centered {
      align-items: center;
      > div {
        width: 100%;
        max-width: 50rem;
      }
    }
    > div {
      display: flex;
      overflow-y: auto;
      .question-index-block {
        min-width: 2.5rem;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-right: 0.5rem;
        .question-index {
          font-family: $textFont;
          font-weight: 400;
          font-size: $large-font-size;
          color: $answerColor;
        }
      }
      .question-content {
        width: 25rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .question-title {
          @include title(question);
        }
        .question-subtitle {
          font-family: $textFont;
          font-weight: normal;
          font-size: $medium-font-size;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          color: $subtitleColor;
        }
      }
    }
  }
}

.confirmation-button-under-question {
  visibility: hidden;
  &.showButton {
    visibility: visible;
  }
}

.MuiButtonBase-root.MuiButton-root.question-confirmation-button.MuiButton-contained {
  background-color: $buttonColor;
  color: $buttonTextColor;
  &:hover {
    @include lightenBackgroundColor();
  }
}

@media only screen and (max-width: $desktop-width) {
  .survey-question .question-container > div .question-index-block {
    min-width: 1.5rem;
    .question-index {
      font-size: 1.25rem;
    }
  }
  .survey-question {
    .question-container {
      &.horizontal {
        align-items: unset;
      }
      margin-bottom: 3rem;
      justify-content: center;
      padding: 2rem 0;
      align-items: center;
      > div {
        .question-index-block {
          .question-index {
            font-size: $medium-font-size;
          }
        }
        .question-content {
          flex-grow: 1;
          width: 17rem;
          .question-title {
            font-size: $medium-font-size;
          }
          .question-subtitle {
            font-size: $small-font-size;
          }
        }
      }
    }
  }
  .MuiButtonBase-root.MuiButton-root.option-checkbox.horizontal + .MuiButtonBase-root.MuiButton-root.MuiButton-text.option-checkbox {
    margin-left: 0.2rem;
  }
  .MuiButtonBase-root.MuiButton-root.question-confirmation-button.MuiButton-contained {
    min-height: 2.75rem;
    background-color: $buttonColor;
    color: $buttonTextColor;
  }
}

@media only screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
  .survey-question .question-container > div .question-content {
    width: 22rem;
  }
}
