@import '../../theme/utils/variables.scss';

.survey-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  height: 100vh;
  background-color: $pageBackgroundColor;
}
