@import 'theme/utils/variables.scss';

body {
  font-family: $textFont;
  color: $titleColor;
  margin: 0;
  a {
    color: $buttonColor;
  }
  .MuiButtonBase-root {
    font-family: $ctaFont;
  }
  .project-info-spinner {
    height: 100vh;
  }
}
