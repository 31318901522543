@import '../../theme/utils/variables.scss';

.survey-radioButtonsGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .radioButtonsGroup-vertical {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 17rem;
    .MuiFormControl-root {
      flex-grow: 1;
    }
  }
  .radioButtonsGroup-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .range-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $titleColor;
  }
}

@media only screen and (max-width: $desktop-width) {
  .survey-radioButtonsGroup.radioButtonsGroup-vertical {
    min-width: 19rem;
  }
}
