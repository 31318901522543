@import './defaultVariables.scss';

//color error
$color-error: #f44336;

$extralarge-font-size: 3.5rem;
$large-font-size: 1.5rem;
$medium-font-size: 1.25rem;
$small-font-size: 1rem;
$extrasmall-font-size: 0.875rem;

//width of start responsive adgiustamen
$tablet-width: 47.5rem; // 760px
$desktop-width: 64rem; //1024px

$titleColor: var(--titleColor, $titleColor);
$titleColorLight: var(--titleColorLight, $titleColorLight);
$titleColorLighter: var(--titleColorLighter, $titleColorLighter);
$titleColorDark: var(--titleColorDark, $titleColorDark);
$subtitleColor: var(--subtitleColor, $subtitleColor);
$subtitleColorLight: var(--subtitleColorLight, $subtitleColorLight);
$subtitleColorLighter: var(--subtitleColorLighter, $subtitleColorLighter);
$subtitleColorDark: var(--subtitleColorDark, $subtitleColorDark);
$answerColor: var(--answerColor, $answerColor);
$answerColorLight: var(--answerColorLight, $answerColorLight);
$answerColorLighter: var(--answerColorLighter, $answerColorLighter);
$answerColorDark: var(--answerColorDark, $answerColorDark);
$buttonTextColor: var(--buttonTextColor, $buttonTextColor);
$buttonColor: var(--buttonColor, $buttonColor);
$pageBackgroundColor: var(--pageBackgroundColor, $pageBackgroundColor);
$textFont: var(--textFont, $textFont);
$ctaFont: var(--ctaFont, $ctaFont);
