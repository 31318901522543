@import './variables.scss';

@keyframes blinkAnimationTiming {
  0% {
    box-shadow: $answerColorLight 0px 0px 0px 0.13rem inset;
    background-color: $answerColorLighter;
  }
  25% {
    box-shadow: $answerColorLighter 0px 0px 0px 0.2rem inset;
    background-color: $answerColor;
  }
  50% {
    box-shadow: $answerColorLight 0px 0px 0px 0.13rem inset;
    background-color: $answerColorLighter;
  }
  100% {
    box-shadow: $answerColorLighter 0px 0px 0px 0.2rem inset;
    background-color: $answerColor;
  }
}

@mixin blinkAnimation {
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 0.4s;
  animation-name: blinkAnimationTiming;
}
