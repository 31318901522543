@import '../../theme/utils/variables';
@import '../../theme/utils/animation';

// CHECKBOX AND RADIO
.MuiButtonBase-root.MuiButton-root.option-checkbox {
  max-width: 25rem;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: $answerColor 0px 0px 0px 0.09rem inset;
  color: $answerColor;
  cursor: pointer;
  align-items: center;
  font-family: $textFont;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $answerColorLighter;
  &:hover {
    background-color: $answerColorLight;
    color: $titleColor;
  }
  &.horizontal {
    flex-grow: 1;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    .MuiButton-label {
      display: flex;
      align-items: center;
      justify-content: center;
      .option-label {
        margin-left: 0rem;
      }
    }
    + .MuiButtonBase-root.MuiButton-root.MuiButton-text.option-checkbox {
      margin-left: 1rem;
    }
    &.selected {
      background-color: $answerColor;
      color: $pageBackgroundColor;
    }
  }

  &.selected {
    box-shadow: $answerColor 0px 0px 0px 0.13rem inset;
    &.clicked {
      @include blinkAnimation;
    }
  }
  .option-icon-hidden {
    visibility: hidden;
  }
  .option-icon {
    visibility: visible;
  }
  &.vertical.Mui-disabled.Mui-disabled {
    opacity: 0.5;
  }
}

.MuiButton-root {
  text-transform: none;
}

.option-checkbox-container {
  display: flex;
  align-items: center;
}

.option-box {
  border: 0.095rem solid $answerColor;
  padding: 0;
  min-width: 1.305rem;
  border-radius: 0.25rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  + .option-text-label {
    margin-left: 1rem;
  }
}

.option-text-label {
  text-transform: none;
  max-width: 19rem;
  font-size: 1rem;
  text-align: left;
}

@media only screen and (max-width: $desktop-width) {
  // CHECKBOX
  .option-box {
    border: 0.085rem solid $answerColor;
    font-size: $extrasmall-font-size;
    &.horizontal {
      padding: 0.5rem;
    }
  }
  .option-text-label {
    font-size: $extrasmall-font-size;
    text-align: left;
  }
  .survey-radioButtonsGroup .radioButtonsGroup-horizontal .option-checkbox {
    &.horizontal {
      min-width: 2.75rem; //overwrite material ui button min width
      padding: 1rem 0.5rem;
    }
  }
}
