@import '../../theme/utils/variables.scss';

.error-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $color-error;
}

.error-image-label {
  font-size: $extrasmall-font-size;
}
