@import 'theme/utils/variables.scss';

.spinner-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiCircularProgress-root.spinner.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate {
  color: $buttonColor;
}
