@import '../../theme/utils/variables.scss';
@import '../../theme/utils/mixins.scss';

.survey-form {
  overflow-y: auto;
  .form-registry {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .form-option {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .option-label {
        font-family: $textFont;
        font-size: $extrasmall-font-size;
        color: $titleColor;
      }
      .option-input {
        height: 2.7rem;
        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
          transition: none;
          border-bottom-color: $titleColor;
        }
        &.MuiInput-underline.Mui-error:before,
        &.MuiInput-underline.Mui-error:after {
          border-bottom-color: $color-error;
        }
        // chrome autocomplete override
        input:-webkit-autofill {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: $titleColor;
        }
      }

      .flag-dropdown {
        .selected-flag {
          padding: 0;
          .arrow {
            left: 2.3rem;
            border-left: 0.375rem solid transparent;
            border-right: 0.375rem solid transparent;
          }
        }
        .selected-flag:focus:before,
        .selected-flag.open:before {
          content: none;
        }
      }

      .checkbox-container {
        display: inline-flex;
        margin-top: 0.5rem;
        .MuiCheckbox-root {
          padding-left: 0;
          background: none;
        }
        .checkbox-text-container {
          display: flex;
          flex-direction: column;
          .checkbox-label {
            color: $titleColor;
            font-family: $textFont;
            font-size: $extrasmall-font-size;
            flex-grow: 1;
            display: flex;
            align-items: center;
          }
        }
      }

      label.Mui-error {
        color: $color-error;
        font-size: $extrasmall-font-size;
        flex-grow: 1;
      }

      .hidden {
        visibility: hidden;
      }
    }
    .survey-form-error {
      display: inline-flex;
      align-items: center;
      color: $color-error;
      border-radius: 0.5rem;
      background-color: $pageBackgroundColor;
      margin-top: 0.25rem;
      padding: 0.25rem;
      font-size: $small-font-size;
      width: fit-content;
      > label {
        margin-left: 0.5rem;
      }
    }
    .form-submit-btn {
      display: flex;
      justify-content: flex-end;
      .MuiButtonBase-root {
        background-color: $buttonColor;
        color: $buttonTextColor;
        &:hover {
          @include lightenBackgroundColor();
        }
      }
    }
    .form {
      + .form-submit-btn {
        margin-top: 1rem;
      }
    }
  }
  .form-image {
    min-width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  input.MuiInputBase-input {
    font-family: $textFont;
    background: none;
    border: none;
  }
  input.MuiInputBase-input:focus {
    background: none;
    box-shadow: none;
  }

  .MuiCheckbox-colorPrimary,
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $titleColor;
    &.Mui-error {
      color: $color-error;
    }
  }

  .MuiInputBase-root {
    color: $titleColor;
  }
}

.text-privacy {
  color: $titleColor;
  font-size: $extrasmall-font-size;
}

.title {
  margin-bottom: 2rem;
  font-weight: normal;
}

@media only screen and (max-width: $desktop-width) {
  .survey-form .form-registry .form-submit-btn .MuiButtonBase-root {
    height: 2.75rem;
  }
}

