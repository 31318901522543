@import '../../theme/utils/variables.scss';

.survey-slide {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.left {
    flex-direction: row-reverse;
  }
  &.full::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .slide-content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    .slide-content-progressive {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.55rem;
      align-items: center;
    }
    &.form {
      overflow: hidden;
      padding: 0rem;
    }
  }

  .slide-question-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 50%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: $desktop-width) {
  .survey-slide {
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column-reverse;
    &.left {
      flex-direction: column-reverse;
    }
    &.transition {
      overflow: hidden;
    }
    .slide-content {
      padding: 1rem;
      overflow-x: auto;
      justify-content: unset;
    }
    .slide-question-image {
      height: 15rem;
    }
  }
}
