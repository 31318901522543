@import '../../theme/utils/variables.scss';
@import '../../theme/utils/mixins.scss';

.survey-slide-buttons {
  align-self: flex-end;
  display: flex;
  align-items: center;
  &.question {
    .buttons-label {
      visibility: visible;
    }
  }
  .buttons-label {
    visibility: hidden;
    font-family: $textFont;
    font-size: 0.875rem;
    color: $titleColor;
    + .slide-buttons {
      margin-left: 1rem;
    }
  }
  .slide-button {
    border: solid 0.065rem $buttonColor;
    color: $buttonColor;
    background-color: $buttonTextColor;
    border-radius: 0.125rem;
    &.MuiButtonBase-root.Mui-disabled {
      box-shadow: 0rem 3px 0.065rem -0.125rem rgba(0, 0, 0, 0.2), 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.065rem 0.3125rem 0rem rgba(0, 0, 0, 0.12);
      opacity: 0.5;
    }
    &.slide-button {
      padding: 0.312rem;
      border: solid 0.065rem $buttonColor;
      box-shadow: 0rem 3px 0.065rem -0.125rem rgba(0, 0, 0, 0.2), 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.065rem 0.3125rem 0rem rgba(0, 0, 0, 0.12);
    }
    + .slide-button {
      margin-left: 0.5rem;
    }
    &:hover {
      color: $buttonTextColor;
      border: solid 0.065rem $buttonColor;
      background-color: $buttonColor;
    }
    &:active {
      box-shadow: $buttonColor 0rem 0rem 0rem 0.09rem inset;
      color: $buttonTextColor;
      background-color: $buttonColor;
    }
  }
}

@media only screen and (max-width: $desktop-width) {
  .survey-slide-buttons {
    box-sizing: border-box;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    position: fixed;
    bottom: 0rem;
    width: 100%;
    left: 0rem;
    .buttons-label {
      visibility: hidden;
    }
    &.question {
      border-top: solid 0.1rem $answerColor;
      background-color: $pageBackgroundColor;
      .buttons-label {
        visibility: visible;
      }
    }
    .slide-button.slide-button {
      height: 2.75rem;
      width: 2.75rem;
    }
  }
}
