@import './variables.scss';

@mixin title($titleType) {
  font-family: $textFont;
  color: $titleColor;
  @if $titleType == question {
    font-size: 1.5rem;
  }

  @if $titleType == slide {
    font-weight: bold;
  }
}

@mixin lightenBackgroundColor() {
  filter: brightness(1.25);
}
